import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import MainNav from "../main-nav/main-nav"
import SocialBlock from "../social-block/social-block"

import cn from "./side-drawer.module.scss"

const SideDrawer = () => {
  const [showStyle, setShowStyle] = useState({})

  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 150) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  useEffect(() => {
    setShowStyle({ width: "100vw", height: "100vh" })
  }, [])

  return (
    <div style={showStyle} className={cn.sideDrawer}>
      <Img fixed={data.logo.childImageSharp.fixed} className={cn.logo} />
      <MainNav mobile={true} />
      <SocialBlock className={cn.socials} />
    </div>
  )
}

SideDrawer.propTypes = {
  clicked: PropTypes.func.isRequired,
}

export default SideDrawer
