import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

import MainNav from "../main-nav/main-nav"

import cn from "./header.module.scss"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          fixed(width: 213) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <header>
      <div className={cn.content}>
        <Link className={cn.logo} to="/" title="Sofia Lima Sousa">
          <Img fixed={data.logo.childImageSharp.fixed} />
        </Link>
        <MainNav />
        {/* <nav className={cn.mainNav}>
          <ul>
            <li>
              <Link to="/" className={cn.link} activeClassName={cn.linkActive}>
                Illustrations
              </Link>
            </li>
            <li>
              <Link
                to="/books"
                className={cn.link}
                activeClassName={cn.linkActive}
              >
                Books
              </Link>
            </li>
            <li>
              <a
                href="http://shop.sofialimasousa.com/en/"
                target="_blank"
                rel="noopener noreferrer"
                className={cn.link}
              >
                Shop
              </a>
            </li>
            <li>
              <Link
                to="/about"
                className={cn.link}
                activeClassName={cn.linkActive}
              >
                About
              </Link>
            </li>
          </ul>
        </nav> */}
      </div>
    </header>
  )
}

export default Header
