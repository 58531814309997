import React from "react"

import cn from "./social-block.module.scss"

import Insta from "../../images/socials/instagram.svg"
import Facebook from "../../images/socials/facebook.svg"
import MailIcon from "../../images/socials/envelope.svg"

const SocialBlock = () => (
  <div className={cn.socials}>
    <a
      href="https://www.instagram.com/sofialimasousa/"
      target="_blank"
      rel="noopener noreferrer"
      className={cn.iconLink}
    >
      <img src={Insta} alt="Instagram" />
    </a>
    <a
      href="https://www.facebook.com/sofialimasousa/"
      target="_blank"
      rel="noopener noreferrer"
      className={cn.iconLink}
    >
      <img src={Facebook} alt="Facebook" />
    </a>
    <a href="mailto:hello@sofialimasousa.com" className={cn.iconLink}>
      <img src={MailIcon} alt="E-Mail" />
    </a>
  </div>
)

export default SocialBlock
