import React, { useState } from "react"
import PropTypes from "prop-types"

import Header from "../header/header"
import Footer from "../footer/footer"
import SideDrawer from "../side-drawer/side-drawer"

import "./layout.scss"

const Layout = ({ children }) => {
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [hamburgerClass, setHamburgerClass] = useState("hamburger-button")

  const toggleShowMobileMenu = () => {
    if (showMobileMenu) {
      setShowMobileMenu(false)
      setHamburgerClass("hamburger-button")
    } else {
      setShowMobileMenu(true)
      setHamburgerClass("hamburger-button open")
    }
  }

  return (
    <>
      <button
        onClick={toggleShowMobileMenu}
        className={hamburgerClass}
        aria-label="Menu"
      ></button>
      {showMobileMenu && (
        <SideDrawer clicked={toggleShowMobileMenu} className="side-drawer" />
      )}
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
