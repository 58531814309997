import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import cn from "./main-nav.module.scss"

const MainNav = ({ mobile }) => (
  <nav className={`${cn.mainNav} ${mobile ? cn.mobile : ""}`}>
    <ul>
      <li>
        <Link to="/" className={cn.link} activeClassName={cn.linkActive}>
          Illustrations
        </Link>
      </li>
      <li>
        <Link to="/books" className={cn.link} activeClassName={cn.linkActive}>
          Books
        </Link>
      </li>
      <li>
        <a
          href="http://shop.sofialimasousa.com/en/"
          target="_blank"
          rel="noopener noreferrer"
          className={cn.link}
        >
          Shop
        </a>
      </li>
      <li>
        <Link to="/about" className={cn.link} activeClassName={cn.linkActive}>
          About
        </Link>
      </li>
    </ul>
  </nav>
)

MainNav.propTypes = {
  mobile: PropTypes.bool,
}

export default MainNav
