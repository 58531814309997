import React from "react"

import SocialBlock from "../social-block/social-block"

import cn from "./footer.module.scss"

const Footer = () => (
  <footer>
    <p className={cn.copyrights}>
      &#169; Sofia Lima Sousa. All rights reserved.
    </p>
    <SocialBlock />
    <p className={cn.sasho}>
      &#123; Build by{" "}
      <a href="https://www.sasho.dev" target="_blank" rel="noopener noreferrer">
        Sasho
      </a>{" "}
      &#125;
    </p>
  </footer>
)

export default Footer
